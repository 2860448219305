<template>
  <div class="space-y-2">
    <div v-for="(row, index) in inputValue" :key="index">
      <div class="flex gap-2">
        <div class="flex-1">
          <InputText
            :modelValue="row.key"
            class="w-full"
            @update:modelValue="value => rowUpdate(index, 'key', value)"
          />
        </div>
        <div class="flex-1">
          <InputText
            :modelValue="row.value"
            class="w-full"
            @update:modelValue="value => rowUpdate(index, 'value', value)"
          />
        </div>
        <div class="flex-none">
          <Button
            icon="pi pi-trash"
            outlined
            severity="danger"
            @click="rowRemove(index)"
          />
        </div>
      </div>
    </div>

    <div v-if="allowAdd" class="flex justify-end">
      <Button
        icon="pi pi-plus"
        label="Add Another"
        size="small"
        @click="rowAdd"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
const emit = defineEmits<{
  'update:modelValue': [value: Record<string, any>],
}>()

const props = withDefaults(defineProps<{
  allowAdd?: boolean,
  modelValue?: Record<string, any>
}>(), {
  allowAdd: true
})

defineOptions({
  inheritAttrs: false
})

const inputValue = computed<{
  key: string,
  value: any
}[]>({
  get() {
    if (!props.modelValue) return []
    return Object.entries(props.modelValue).map(([key, value]) => ({ key, value }))
  },
  set(value) {
    const newValue = value.reduce((acc, { key, value }) => ({ ...acc, [key]: value }), {})
    emit('update:modelValue', newValue)
  }
})

const rowAdd = () => {
  const newValue = [...inputValue.value]
  newValue.push({ key: '', value: '' })
  inputValue.value = newValue
}

const rowRemove = (index: number) => {
  const newValue = [...inputValue.value]
  newValue.splice(index, 1)
  inputValue.value = newValue
}

const rowUpdate = (index: number, key: string, value: any) => {
  const newValue = [...inputValue.value]
  newValue[index] = { ...newValue[index], [key]: value }
  inputValue.value = newValue
}
</script>
