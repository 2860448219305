<template>
  <small v-if="hasError" class="text-red-500">
    <template v-if="isArray">
      <ul>
        <li v-for="message in props.error">{{ message }}</li>
      </ul>
    </template>
    <template v-else>
      {{ error }}
    </template>
  </small>
</template>

<script lang="ts" setup>
const props = defineProps<{
  error: string | string[] | undefined
}>()

const hasError = computed<boolean>(() => {
  if (Array.isArray(props.error) && props.error.length > 0) return true
  return !!props.error;
})

const isArray = computed<boolean>(() => Array.isArray(props.error))
</script>
